import { DefaultSeoProps } from 'next-seo'

const config: DefaultSeoProps = {
  titleTemplate: '%s | Community Fibre',
  defaultTitle: 'Community Fibre',
  description: 'Community Fibre - London\'s fastest 100% full fibre broadband provider',
  openGraph: {
    type: 'website',
    locale: 'en_GB',
    url: 'https://communityfibre.co.uk/',
    site_name: 'Community Fibre',
    images: [{
      url: 'https://communityfibre.co.uk/logo.png',
      width: 800,
      height: 600,
      alt: 'Community Fibre',
    }],
  },
  twitter: {
    handle: '@communityfibre',
    site: '@communityfibre',
    cardType: 'summary_large_image',
  },
  additionalLinkTags: [
    {
      rel: 'icon',
      href: '/favicon.ico',
    },
    {
      rel: 'apple-touch-icon',
      href: '/apple-touch-icon.png',
      sizes: '180x180',
    },
    {
      rel: 'icon',
      type: 'image/png',
      href: '/favicon-32x32.png',
      sizes: '32x32',
    },
    {
      rel: 'icon',
      type: 'image/png',
      href: '/favicon-16x16.png',
      sizes: '16x16',
    },
    {
      rel: 'mask-icon',
      href: '/safari-pinned-tab.svg',
      color: '#5bbad5',
    },
  ],
  additionalMetaTags: [
    {
      name: 'facebook-domain-verification',
      content: '0zboaobpxtyptfdzy37o0pksjh4v13',
    },
    {
      name: 'msapplication-TileColor',
      content: '#da532c',
    },
    {
      name: 'theme-color',
      content: '#ffffff',
    },
  ],
}

export default config
